<template>
    <div class="product-insights green-border-box">
      <div >
        <apexcharts type="bar" height="350" :options="chartOptions" :series="series"></apexcharts>
      </div>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      series: [{
        name: '<18',
        data: [1, 6, 4, 9, 10]
      }, {
        name: '18-25',
        data: [13, 23, 20, 8, 13]
      }, {
        name: '25-40',
        data: [23, 25, 29, 31, 21]
      }, {
        name: '40-50',
        data: [23, 26, 16, 25, 30]
      }, {
        name: '50-60',
        data: [14, 9, 6, 12, 10]
      }, {
        name: '60+',
        data: [10, 9, 7, 11, 15]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        xaxis: {
          categories: ['weekly', 'twice a week', 'Monthly', 'Every 2 Months', 'Every 6 Months']
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50
        }
      }
    }
  },
  methods: {
    getSurveyInsights () {
      this.$store.dispatch('getBrandsurveyInsights', this.brandId)
        .then(
          (response) => {
            this.series[0].data = response.body
            this.loading = false
          }
        )
    }
  },

  mounted () {
    this.getSurveyInsights()
  },
  watch: {

  }
}
</script>
