var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("br"),
    _c(
      "div",
      {
        staticClass: "row green-border-box",
        staticStyle: { "background-color": "#F9F9F9" }
      },
      [
        _c(
          "h1",
          {
            staticClass: "purple-color",
            staticStyle: {
              "text-align": "center",
              "font-size": "250%",
              "font-weight": "100"
            }
          },
          [_vm._v("Brand & Consumer Insights")]
        ),
        _c("br"),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("h2", [
            _vm._v(
              "How often would you purchase this product at this price\non shelf?"
            )
          ]),
          _c("div", { staticClass: "col-sm-6 " }, [
            _c(
              "div",
              { staticClass: "green-border-box" },
              [
                _c("h4", { staticClass: "purple-color text-center" }, [
                  _vm._v("%  by AGE")
                ]),
                _c("age-wise-insight")
              ],
              1
            )
          ]),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3)
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "row green-border-box",
        staticStyle: { "background-color": "#F9F9F9" }
      },
      [
        _c(
          "a",
          {
            staticClass: "btn btn-positive pull-right",
            staticStyle: { margin: "5px" },
            attrs: { href: "javascript:;" }
          },
          [_vm._v("EXPORT")]
        ),
        _c(
          "h1",
          {
            staticClass: "purple-color",
            staticStyle: {
              "text-align": "center",
              "font-size": "250%",
              "font-weight": "100"
            }
          },
          [_vm._v("Campaign Success Metrics")]
        ),
        _c("br"),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("89%")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Lead Conversion Rate")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("23%")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Sales Conversion Rate")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("$2744")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Saved in CAC")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("66%")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Retention Rate")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("42.1%")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Upsell Ratio")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("1.1")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Trips Frequency/Month")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("3.14")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Items Per Basket Size")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("$49")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Avg Perchased Value")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("16.2h")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Avg Lead Response Time")
            ]),
            _c("br"),
            _c("hr")
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-4", staticStyle: { "text-align": "center" } },
          [
            _c("h1", { staticClass: "purple-color big-percentage" }, [
              _vm._v("6 min")
            ]),
            _c("h1", { staticClass: "percentage-label" }, [
              _vm._v("Fastest Lead Response Time")
            ]),
            _c("br"),
            _c("hr")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "green-border-box" }, [
        _c("h4", { staticClass: "purple-color text-center" }, [
          _vm._v("% by GENDER")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "green-border-box" }, [
        _c("h4", { staticClass: "purple-color text-center" }, [
          _vm._v("% by GENERATION")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "green-border-box" }, [
        _c("h4", { staticClass: "purple-color text-center" }, [
          _vm._v(" % by CHILDREN")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }