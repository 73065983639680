<template>
  <div>
    <div class="row green-border-box" style="background-color: #F9F9F9;">
      <a href="javascript:;" class="btn btn-positive pull-right" style="margin:5px;">EXPORT</a>

      <h1 style="text-align:center;font-size: 250%;font-weight: 100;" class="purple-color">Campaign Success Metrics</h1><br/>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">89%</h1>
        <h1 class="percentage-label">Lead Conversion Rate</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">23%</h1>
        <h1 class="percentage-label" >Sales Conversion Rate</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">$2744</h1>
        <h1 class="percentage-label" >Saved in CAC</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">66%</h1>
        <h1 class="percentage-label" >Retention Rate</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">42.1%</h1>
        <h1 class="percentage-label" >Upsell Ratio</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">1.1</h1>
        <h1 class="percentage-label" >Trips Frequency/Month</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">3.14</h1>
        <h1 class="percentage-label" >Items Per Basket Size</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">$49</h1>
        <h1 class="percentage-label" >Avg Perchased Value</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">16.2h</h1>
        <h1 class="percentage-label" >Avg Lead Response Time</h1><br/>
        <hr/>
      </div>
      <div class="col-sm-4" style="text-align:center;">
        <h1 class="purple-color big-percentage">6 min</h1>
        <h1 class="percentage-label" >Fastest Lead Response Time</h1><br/>
        <hr/>
      </div>
    </div>
    <br/>
    <div class="row green-border-box" style="background-color: #F9F9F9;">
      <h1 style="text-align:center;font-size: 250%;font-weight: 100;" class="purple-color">Brand & Consumer Insights</h1><br/>
      <div class="col-sm-12">
        <h2>How often would you purchase this product at this price
on shelf?</h2>
        <div class="col-sm-6 ">
          <div class="green-border-box">
            <h4 class="purple-color text-center">%  by AGE</h4>
            <age-wise-insight />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="green-border-box">
            <h4 class="purple-color text-center">% by GENDER</h4>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="green-border-box">
            <h4 class="purple-color text-center">% by GENERATION</h4>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="green-border-box">
            <h4 class="purple-color text-center"> % by CHILDREN</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgeWiseInsight from '@/components/Brand/NewReports/AgeWiseInsight.vue'

export default {
  name: 'Dashboard',
  components: {
    AgeWiseInsight
  },
  data: function () {
    return {
      brandId: 0,
      mixedSeries: [{
        name: 'Growth',
        type: 'column',
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
      }, {
        name: 'Sales',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
      }],
      mixedchartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
          '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
        ],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: 'Tasks'
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' tasks'
              }
              return y
            }
          }
        }
      },

      pieS: [10, 90],
      swseries: [44, 55, 41, 17, 15],
      swchartOptions: {
        labels: ['California', 'Washington', 'Oregon', 'Florida', 'Texas']
      },
      PchartOptions: {
        chart: {
          width: 380,
          type: 'pie'
        },
        labels: ['Home', 'In-Store']
      },
      loadingStripeBalance: true,
      stripeBalance: 0,
      usersCountTasks: [],
      brandsCountTasks: [],
      genderDemographics: [],
      loadingPicklAverageTimes: true,
      averagePicklTimes: [],
      busy: true,
      allChartData: [],
      viewby: '1y',
      viewAverageTime: 'last-1y',
      getComparisionTime: 'last-1y',
      activeTab: 'pickl',
      chartData: [],
      ageDemographics: [],
      paymentChartData: [],
      dashboardContent: {},
      userRole: 'staff',
      userUrl: 'sadmin',
      columnD: [{
        name: 'Task Launched',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
        name: 'Tasks Completed',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      }, {
        name: 'Product Bought ',
        data: [5, 7, 4, 14, 3, 10, 5, 9, 6]
      }],

      columnF: [{
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      }, {
        name: 'Free Cash Flow',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
      }],
      series: [{
        name: 'Insights',
        data: [250, 200, 240, 245, 200, 210, 280, 300, 150]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
        },
        yaxis: {
          title: {
            text: '$ (thousands)'
          }
        },
        fill: {
          opacity: 1
        }
      },
      barChartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb']
        },
        yaxis: {
          title: {
            text: 'Tasks'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + 'pickls'
            }
          }
        }
      }
    }
  },
  methods: {

  },
  created () {
    this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.$store.state.userData.onBoarded
  },

  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  },
  watch: {
    viewby () {
      this.getSadminReports()
    },
    viewAverageTime () {
      this.getPicklAverageTimes()
    }
  }
}
</script>

<style scoped>
.padd-graph {
  padding: 25px;
}
.big-percentage {
  font-size:400%;
}
.percentage-label {
  font-weight: 100;
}
.green-border-box {
  margin-bottom: 10px;
}

.tab-content {
  overflow: hidden;
}

>>> .graph-label,
>>> .apexcharts-xaxis tspan {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Google Sans", "Product Sans", sans-serif !important;
}

>>> .graph-label {
  font-size: 15px;
}

>>> .apexcharts-tooltip {
  overflow: visible;
}

>>> .graph-tooltip {
  position: relative;
  background: #782b54;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 5px;
}

/* .graph-tooltip:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(120, 43, 84, 0);
  border-top-color: #782b54;
  border-width: 5px;
  margin-left: -5px;
} */
.purple-color {
  color: #782b54;
}

hr {
  background: #0EDB6D;
  height: 1px;
  width: 90%;
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  >>> .apexcharts-xaxis-label:nth-last-child(even) {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .sort-control {
    margin-top: 15px;
  }

  >>> .filter3m .apexcharts-xaxis-label,
  >>> .filter1m .apexcharts-xaxis-label {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label:first-child,
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter1m .apexcharts-xaxis-label:first-child,
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(5),
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(4) {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .sort-control {
    margin-top: 15px;
  }

  >>> .apexcharts-xaxis tspan {
    font-size: 12px;
  }

  .padd-graph {
    padding: 15px;
  }

  >>> .filter6m .apexcharts-xaxis-label:nth-last-child(2n) {
    display: none;
  }

  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n-1),
  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n),
  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n-2) {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label,
  >>> .filter1m .apexcharts-xaxis-label {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label:first-child,
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter1m .apexcharts-xaxis-label:first-child,
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(2) {
    display: block;
  }
}
</style>
