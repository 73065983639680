var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    _c(
      "div",
      [
        _c("apexcharts", {
          attrs: {
            type: "bar",
            height: "350",
            options: _vm.chartOptions,
            series: _vm.series
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }